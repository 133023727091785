const resolveForm = rawForm => {
  const form = [];
  rawForm.forEach(f => {
    switch (f.type) {
      case "field":
        form.push({
          ...f,
          id: f.fieldSlug,
          name: f.fieldSlug,
          type: f.inputType,
        });
        break;
      case "selectFieldDropdown":
        form.push({
          ...f,
          label: f.label,
          defaultValue: f.defaultValue,
          options: f.options,
          id: f.fieldSlug,
          name: f.fieldSlug,
          type: "select",
        });
        break;
      case "radio":
        form.push({
          ...f,
          defaultValue: f.defaultValue,
          options: f.options.map(o => ({ ...o, id: o.value })),
          name: f.fieldSlug,
          type: "radio",
        });
        break;
      case "checkboxes":
        form.push({
          ...f,
          defaultValue: f.checked,
          id: f.fieldSlug,
          name: f.fieldSlug,
          type: "checkbox",
        });
        break;
      case "fileUpload":
        form.push({
          ...f,
          id: f.fieldSlug,
          name: f.fieldSlug,
          type: "file",
        });
        break;
      default:
        // do nothing
        break;
    }
  });
  return form;
};

export default resolveForm;
