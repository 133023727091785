import resolveImage from "@dataResolvers/resolveImage";
import resolveForm from "@dataResolvers/resolveForm";

const resolvePageBuilder = blocks => {
  const resolvedblocks = [];
  let reverse = false;
  let overlap = false;
  let paddingTop = false;
  let paddingBottom = false;
  let prevColor = null;
  let currentColor = null;
  let nextColor = null;

  blocks.forEach((block, i) => {
    prevColor = blocks[i - 1]?.bgColor;
    currentColor = blocks[i].bgColor;
    nextColor = blocks[i + 1]?.bgColor;
    paddingTop = prevColor !== currentColor;
    paddingBottom = currentColor !== nextColor;
    const { type, uid } = block;

    switch (type) {
      case "accordionList":
        resolvedblocks.push({
          type,
          uid,
          paddingTop,
          paddingBottom,
          bgColor: block.bgColor,
          copy: block.copy,
          items: block.items?.map(item => {
            return {
              ...item,
            };
          }),
        });
        break;
      case "applicationForm":
        resolvedblocks.push({
          type,
          uid,
          paddingTop,
          paddingBottom,
          order: i,
          bgColor: block.bgColor,
          copy: block.copy,
          form: {
            uid: block.form[0]?.uid,
            title: block.form[0]?.title,
            email: block.form[0]?.email,
            fields: resolveForm(block.form[0]?.formBuilder),
          },
        });
        break;
      case "articleGrid":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          articles: block.articles?.map(article => {
            return {
              uid: article.uid,
              heading: article.title,
              image: resolveImage(article.metaImage),
              url: article.url,
            };
          }),
          button: block.button,
        });
        break;
      case "blockQuote":
        resolvedblocks.push({
          type,
          uid,
          quote: block.testimonial?.[0]?.quote,
          attribution: block.testimonial?.[0]?.name,
          position: block.testimonial?.[0]?.position,
          image: resolveImage(block.testimonial?.[0]?.image),
          link: block.cta,
        });
        break;
      case "button":
        resolvedblocks.push({
          type,
          uid,
          url: block.link?.url,
          text: block.link?.text,
          style: block.style,
        });
        break;
      case "cardCarousel":
        resolvedblocks.push({
          type,
          uid,
          bgColor: block.bgColor,
          copy: block.copy,
          slides: block.slides?.map(s => ({
            ...s,
            image: resolveImage(s.image),
          })),
        });
        break;
      case "careersList":
        resolvedblocks.push({
          type,
          uid,
          bgColor: block.bgColor,
          image: resolveImage(block.image),
          copy: block.copy,
          careers: block.careers?.map(c => ({
            ...c,
          })),
        });
        break;
      case "caseStudyCarousel":
        resolvedblocks.push({
          type,
          uid,
          bgColor: block.bgColor,
          copy: block.copy,
          caseStudies: block.caseStudies?.map(caseStudy => {
            return {
              uid: caseStudy.uid,
              heading: caseStudy.heading,
              descriptor: caseStudy.descriptor,
              image: resolveImage(caseStudy.image),
              url: caseStudy.uri,
            };
          }),
          button: block.button,
        });
        break;
      case "caseStudyGrid":
        resolvedblocks.push({
          type,
          uid,
          caseStudies: block.caseStudies?.map(caseStudy => {
            return {
              uid: caseStudy.uid,
              heading: caseStudy.heading,
              descriptor: caseStudy.descriptor,
              image: resolveImage(caseStudy.image),
              url: caseStudy.uri,
            };
          }),
        });
        break;
      case "clientCarousel":
        resolvedblocks.push({
          type,
          uid,
          bgColor: block.bgColor,
          copy: block.copy,
          slides: block.clients?.map(client => {
            return {
              uid: client.uid,
              image: resolveImage(client.image),
            };
          }),
        });
        break;
      case "containedGrid":
        resolvedblocks.push({
          type,
          uid,
          bgColor: block.bgColor,
          copy: block.copy,
          items: block.items?.map(item => {
            return {
              ...item,
            };
          }),
          overlap,
        });
        break;
      case "copy":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
        });
        break;
      case "cta":
        resolvedblocks.push({
          type,
          uid,
          topBg: prevColor,
          bottomBg: nextColor,
          copy: block.copy,
          button: block.button,
          image: resolveImage(block.image),
        });
        break;
      case "formSelect":
        resolvedblocks.push({
          type,
          uid,
          idx: i,
          bgColor: block.bgColor,
          heading: block.heading,
          descriptor: block.descriptor,
          options: block.forms?.map(form => {
            return {
              uid: form.uid,
              heading: form.heading,
              descriptor: form.descriptor,
              title: form.form?.[0]?.title,
              email: form.form?.[0]?.email,
              form: resolveForm(form.form?.[0]?.formBuilder),
            };
          }),
        });
        break;
      case "featureGrid":
        resolvedblocks.push({
          type,
          uid,
          bgColor: block.bgColor,
          heading: block.heading,
          features: block.features?.map(f => {
            return {
              uid: f.uid,
              heading: f.heading,
              descriptor: f.descriptor,
              link: f.link,
            };
          }),
        });
        break;
      case "flowChart":
        resolvedblocks.push({
          type,
          uid,
          paddingTop,
          paddingBottom,
          bgColor: block.bgColor,
          copy: block.copy,
          items: block.items?.map(item => {
            return {
              ...item,
            };
          }),
        });
        break;
      case "hero":
        reverse = !reverse;
        resolvedblocks.push({
          type,
          uid,
          lighten: block.lighten,
          copy: block.copy,
          button: block.button,
          link: block.link,
          image: resolveImage(block.image),
        });
        break;
      case "imageCopy":
        reverse = !reverse;
        resolvedblocks.push({
          type,
          uid,
          reverse,
          paddingTop,
          paddingBottom,
          order: i,
          bgColor: block.bgColor,
          image: resolveImage(block.image),
          graphic: resolveImage(block.graphic),
          subheading: block.subheading,
          copy: block.copy,
          button: block.button,
          link: block.link,
          showRibbons: block.showRibbons,
          color: block.color,
          variant: block.variant,
        });
        break;
      case "imageCopyGrid":
        resolvedblocks.push({
          type,
          uid,
          paddingTop,
          paddingBottom,
          bgColor: block.bgColor,
          copy: block.copy,
          items: block.items?.map(item => ({
            ...item,
            image: resolveImage(item.image),
          })),
        });
        break;
      case "impactGrid":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
          tiles: block.tiles?.map(tile => ({
            ...tile,
            image: resolveImage(tile.image),
          })),
        });
        break;
      case "leadCopy":
        resolvedblocks.push({
          type,
          uid,
          bgColor: block.bgColor,
          subheading: block.subheading,
          copy: block.copy,
          button: block.button,
        });
        break;
      case "map":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
          data: block.data[0]?.url,
        });
        break;
      case "peopleGrid":
        block.bgColor === "white" ? (overlap = true) : (overlap = false);
        resolvedblocks.push({
          type,
          uid,
          bgColor: block.bgColor,
          copy: block.copy,
          people: block.people?.map(p => ({
            ...p,
            headshot: resolveImage(p.headshot),
          })),
        });
        break;
      case "ribbons":
        resolvedblocks.push({
          type,
          uid,
          color: block.color,
          variant: block.variant,
          bgColor: block.bgColor,
          splitColor: block.splitColor,
        });
        break;
      case "sectionHeading":
        resolvedblocks.push({
          type,
          uid,
          order: i,
          bgColor: block.bgColor,
          heading: block.heading,
          center: block.center,
        });
        break;
      case "serviceCarousel":
        resolvedblocks.push({
          type,
          uid,
          bgColor: block.bgColor,
          copy: block.copy,
          services: block.services?.map(s => ({
            ...s,
            image: resolveImage(s.image),
          })),
        });
        break;
      case "serviceGrid":
        resolvedblocks.push({
          type,
          uid,
          paddingTop,
          paddingBottom,
          bgColor: block.bgColor,
          copy: block.copy,
          services: block.services?.map(s => ({
            ...s,
            image: resolveImage(s.image),
            logo: resolveImage(s.logo),
          })),
        });
        break;
      case "simpleList":
        resolvedblocks.push({
          type,
          uid,
          bgColor: block.bgColor,
          copy: block.copy,
          items: block.items,
        });
        break;
      case "testimonialCarousel":
        resolvedblocks.push({
          type,
          uid,
          bgColor: block.bgColor,
          paddingTop,
          paddingBottom,
          testimonials: block.testimonials?.map(t => ({
            ...t,
            image: resolveImage(t.image),
          })),
        });
        break;
      case "twoColumnContent":
        resolvedblocks.push({
          type,
          uid,
          paddingTop,
          paddingBottom,
          bgColor: block.bgColor,
          hideRibbons: block.hideRibbons,
          position: block.position,
          items: block.columnContent[0]?.items?.map(item => ({
            ...item,
          })),
          button: block.button,
        });
        break;
      case "video":
        resolvedblocks.push({
          type,
          uid,
          noPadding: false,
          paddingTop,
          paddingBottom,
          bgColor: block.bgColor,
          videoUrl: block.videoUrl,
          caption: block.caption,
        });
        break;
      default:
        resolvedblocks.push({ type, uid, ...block });
        break;
    }
  });

  return resolvedblocks;
};

export default resolvePageBuilder;
