import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import PageTemplate from "@pageTemplates/HomePage";
// eslint-disable-next-line no-unused-vars
import PageBuilderFragment from "@fragments/PageBuilderFragment";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query HomeQuery {
    craft {
      entry(section: "homePage") {
        title
        ... on Craft_homePage_homePage_Entry {
          # Hero
          copy: copy0
          button: link0 {
            text
            url
          }
          link: link1 {
            text
            url
          }
          image: image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          pageBuilder {
            ...PageBuilderFragment
          }
          slug
          url
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    // Hero
    copy,
    button,
    link,
    image,
    // pb
    pageBuilder,
    // Meta
    title,
    slug,
    url,
    metaTitle,
    metaDescription,
    metaImage,
  } = entry;

  return {
    hero: {
      copy,
      button,
      link,
      image: resolveImage(image),
    },
    blocks: resolvePageBuilder(pageBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  const eagerUrls = [];
  return <HeadTemplate {...rest} data={resolvedData} eagerUrls={eagerUrls} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="HomePageHomePageQuery"
    {...props}
  />
);

export default Page;
