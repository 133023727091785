import React, { useEffect } from "react";
import { useAppState } from "@state";
import loadable from "@loadable/component";
import PageContainer from "@layout/DefaultPageContainer";

const Hero = loadable(() => import("@organisms/Hero"));
const TextCircle = loadable(() => import("@organisms/TextCircle"));
const Cta = loadable(() => import("@organisms/Cta"));
const NewsletterCta = loadable(() => import("@organisms/NewsletterCta"));
const PageBuilder = loadable(() =>
  import("../components/templates/PageBuilder")
);

const HomePage = ({ data, pageContext }) => {
  const { hero, blocks, meta } = data;

  const [, dispatch] = useAppState();
  useEffect(() => {
    dispatch({ type: "setThemeLight" });
  }, []);

  return (
    <PageContainer meta={meta}>
      <Hero {...hero} lighten cover hideRibbons />
      <TextCircle name="watermarkPurpose" />
      <PageBuilder blocks={blocks} />
      <Cta />
      <NewsletterCta />
    </PageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
